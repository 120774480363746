<template>
  <div>
    <h2>这是首页</h2>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>
