<template>
  <RouterView />
  <van-tabbar v-model="active">
    <van-tabbar-item icon="service-o" replace to="/">听力</van-tabbar-item>
    <van-tabbar-item icon="search" @click="openInNewTab('http://www.wph0.cn')">资讯</van-tabbar-item>
    <van-tabbar-item icon="fire-o" @click="openInNewTab('http://www.wph1.cn')">AI</van-tabbar-item>
    <van-tabbar-item icon="friends-o" @click="openInNewTab('http://www.wph6.cn')">起名</van-tabbar-item>
    <van-tabbar-item icon="home-o" replace to="/home">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router'
export default {
  name: 'AppLayout',
  setup() {
    const active = ref(0);
    // 添加路由监听
    const router = useRouter();
    onMounted(() => {
      const currentPath = router.currentRoute.value.path;
      if (currentPath === '/home') {
        active.value = 4; // “我的”对应的索引为 4
      }
    });
    const openInNewTab = (url) => {
      window.open(url, '_blank');
      active.value = 0;
    };
    return {
      active,
      openInNewTab,
    };
  },
  /*,
  created() {
    console.log('当前访问路径：', this.$route.path);
  },*/

};
</script>

<style scoped>
header {
  background-color: #333;
  color: white;
  padding: 10px;
}
nav {
  display: flex;
  justify-content: space-between;
}
nav a {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
}
nav a:hover {
  background-color: #555;
}
</style>
