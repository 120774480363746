import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '../components/AppLayout.vue';
import HomePage from '../components/HomePage.vue';
import TestComponent from "@/components/TestComponent.vue";

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/home',
                component: HomePage
            },
            {
                path: '/',
                component: TestComponent
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
