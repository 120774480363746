<template>

  <van-tabs v-model:active="active" @click-tab="onClickTab">
    <van-tab v-for="item in tabList" :title="item.title" :name="item.id" :key="item.id">
      <!-- 这里是对应标签页的内容 -->
      <!--<div v-for="content in item.content" :key="content">{{ content }}</div>-->
      <div>
        <SrtCloze :options="srtOptions" :key="active"/>
      </div>
      <div>
        <video-player :options="videoOptions" :startTime="startTime" :endTime="endTime" :key="active"/>
      </div>
    </van-tab>
  </van-tabs>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import SrtCloze from '@/components/SrtCloze.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { ref, onMounted, reactive, nextTick  } from 'vue';

export default {
  name: 'TestComponent',
  //在 Vue 3 的setup函数中，没有this上下文。这是因为setup函数是在组件实例创建之前被调用的，所以无法访问组件实例的属性和方法。
  setup() {
    const startTime = ref(0)
    const endTime = ref(0)
    const active = ref(0);
    // 使用 reactive 创建响应式对象
    const videoOptions = reactive({
      autoplay: true,
      controls: true,
      sources: [
        {
          src: '',
          type: 'video/mp4'
        }
      ]
    });
    const srtOptions = reactive({
      src: ''
    });
    const onClickTab = () => {
      const newValue = active.value;
      var activeItem = tabList.value[newValue];
      var srtSrc = activeItem.srt;
      var videoSrc = activeItem.video;
      videoOptions.sources[0].src = videoSrc;
      srtOptions.src = srtSrc;
      //console.log("activeItem.startTime", VideoPlayer.props.startTime)
    };
    onMounted(() => {
      onClickTab()
    });

    const tabList = ref([
      { title: '20241011', id: '0', video:'/20241011_output.mp4', srt:'/20241011_output-zimujiang.com.srt' },
      { title: '20241029', id: '1', video:'/20241106_瑞克与莫蒂.Rick.and.Morty.S03E02.WEB-720p.1280X720.中英双语-电波字幕组.mp4', srt:'/20241106_瑞克与莫蒂-zimujiang.com.srt' },
      // 更多标签页...
    ]);

    return {
      active,
      onClickTab,
      videoOptions,
      srtOptions,
      tabList,
      startTime,
      endTime
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SrtCloze,
    // eslint-disable-next-line vue/no-unused-components
    VideoPlayer,
  },
  computed: {
    ...mapState(['currentItemId', "currentItemStartTime", "currentItemEndTime"]),
  },
  //第一次访问127.0.0.1:8080的时候是1 0 0，后面切换到其他tab还是swipe都不会再触发
  mounted() {
    //console.log('当前 item 的 id：', this.currentItemId);
    //console.log('当前 item 的 startTime：', this.currentItemStartTime);
    //console.log('当前 item 的 endTime：', this.currentItemEndTime);
  },
  watch: {
    //切换tab会变化 0 1 2
    // eslint-disable-next-line no-unused-vars
    active(newValue) {
      console.log("TestComponent.watch.active.newValue", newValue)
    },
    //不变化
    // eslint-disable-next-line no-unused-vars
    currentItemId(newVal) {
      //console.log('currentItemId 发生变化，新值为：', newVal);
    },
    currentItemStartTime(newVal) {
      //console.log("currentItemStartTime.startTime.beforeNew", this.startTime)
      this.startTime = newVal;
      // 触发子组件的重新渲染或更新。当currentItemStartTime变化时，立即更新videoStartTime并将变化传递给子组件。
      this.$forceUpdate();
      //console.log("currentItemStartTime.startTime.afterNew", this.startTime)
      console.log('currentItemStartTime 发生变化，新值为：', newVal);
    },
    currentItemEndTime(newVal) {
      //console.log("currentItemStartTime.endTime.beforeNew", this.endTime)
      this.endTime = newVal;
      //console.log("currentItemStartTime.endTime.afterNew", this.endTime)
      //console.log('currentItemEndTime 发生变化，新值为：', newVal);
    },
  }
};
</script>