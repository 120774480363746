import Vuex from 'vuex';

const store = new Vuex.Store({
    state: {
        currentItemId: 1,
        currentItemStartTime: 0,
        currentItemEndTime: 0,
    },
    mutations: {
        setCurrentItemId(state, id) {
            state.currentItemId = id;
        },
        setCurrentItemStartTime(state, startTime) {
            state.currentItemStartTime = startTime;
        },
        setCurrentItemEndTime(state, endTime) {
            state.currentItemEndTime = endTime;
        }
    }
});

export default store;